export const DOCTOR_REPORT_MARK_INFO = {
    growth: {
        title: 'Прирост выручки',
        low: {
            value: '0',
            markText: 'Очень низкий прирост выручки',
            recomendationText: '',
        },
        medium: {
            value: '15%',
            markText: 'Прирост выручки ниже эталонного',
            recomendationText: '',
        },
        high: {
            value: '20%',
            markText: 'Хороший прирост выручки',
            recomendationText: '',
        },
    },
    newCountByMonth: {
        title: 'Новых клиентов',
        low: {
            value: '0',
            markText: 'Очень мало новых клиентов',
            recomendationText: '',
        },
        medium: {
            value: '40',
            markText: 'Новых клиентов меньше, чем должно быть',
            recomendationText: '',
        },
        high: {
            value: '80',
            markText: 'Хорошее количество новых клиентов',
            recomendationText: '',
        },
    },
    conversion2: {
        title: 'Конверсия с 1 до 2',
        low: {
            value: '0%',
            markText: 'Клиенты почти не возвращаются после первого посещения',
            recomendationText: '',
        },
        medium: {
            value: '30%',
            markText: 'Клиенты не слишком хорошо приходят повторно после первого посещения',
            recomendationText: '',
        },
        high: {
            value: '60%',
            markText: 'Клиенты хорошо приходят повторно после первого посещения',
            recomendationText: '',
        },
    },
    conversion3: {
        title: 'Конверсия с 2 до 3',
        low: {
            value: '0',
            markText: 'Клиенты практически не становятся постоянными после второго посещения',
            recomendationText: '',
        },
        medium: {
            value: '20%',
            markText: 'Клиенты не слишком хорошо становятся постоянными после второго посещения',
            recomendationText: '',
        },
        high: {
            value: '50%',
            markText: 'Клиенты часто становятся постоянными после второго посещения',
            recomendationText: '',
        },
    },
    returnRate: {
        title: 'Удержание клиентов',
        low: {
            value: '0',
            markText: 'Очень часто клиенты перестают ходить к вам',
            recomendationText: '',
        },
        medium: {
            value: '50%',
            markText: 'Достаточно часто клиенты перестают ходить к вам',
            recomendationText: '',
        },
        high: {
            value: '70%',
            markText: 'Клиенты хорошо возвращаются после после очередного посещения',
            recomendationText: '',
        },
    },
    productsPercent: {
        title: 'Доля продаж товаров',
        low: {
            value: '0',
            markText: 'Очень низкая доля продаж товаров',
            recomendationText: '',
        },
        medium: {
            value: '10',
            markText: 'Недостаточная доля продаж товаров',
            recomendationText: '',
        },
        high: {
            value: '17',
            markText: 'Хорошая доля продаж товаров',
            recomendationText: '',
        },
    },
    itemsCount: {
        title: 'Кол-во услуг на клиента',
        low: {
            value: '0',
            markText: 'Каждый клиент потребляет одни и те же услуги',
            recomendationText: '',
        },
        medium: {
            value: '3',
            markText: 'Каждый клиент потребляет недостаточное количество услуг',
            recomendationText: '',
        },
        high: {
            value: '5',
            markText: 'Каждый клиент потребляет множество услуг',
            recomendationText: '',
        },
    },
    recOtherDay: {
        title: 'Оперативная запись',
        low: {
            value: '0',
            markText: 'Вы не записываете на будущее сразу после визита',
            recomendationText: '',
        },
        medium: {
            value: '1%',
            markText: 'Вы недостаточно записываете на будущее сразу после визита',
            recomendationText: '',
        },
        high: {
            value: '10%',
            markText: 'Вы хорошо записываете на будущее сразу после визита',
            recomendationText: '',
        },
    },
}
