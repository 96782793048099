
import moment from 'moment'
import Vue, { PropType } from 'vue'
import api from '@/api/api'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import SpinnerLoader from '@/components/SpinnerLoader.vue'
import { Connector, Project, ProjectType } from '@/types/main'
import { CONNECTOR_TYPES } from '@/vars/general'
import _ from 'lodash'
import DoctorReportCard from '../components/DoctorReportCard.vue'
import { DoctorReportMarkData } from '../types'
import { DOCTOR_REPORT_MARK_INFO } from '../consts'
import CustomActivatorSelect from '../../../components/inputs/CustomActivatorSelect.vue'
import BaseInputOutlined from '../../../components/inputs/BaseInputOutlined.vue'
export default Vue.extend({
    name: 'DoctorReport',
    components: {
        Breadcrumbs,
        SpinnerLoader,
        DoctorReportCard,
        CustomActivatorSelect,
        BaseInputOutlined,
    },

    data: () => ({
        pageLoading: false,
        dashboardDataLoading: false,
        dashboardData: [],
        projectType: null as null | string,
        filialList: [] as any[],
        selectedProjectId: null as null | string,
    }),
    watch: {},
    computed: {
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },

        connector(): Connector | undefined {
            return this.project.connectors.find(el => el.connectorType === CONNECTOR_TYPES.GCB2)
        },
        breadcrumbs(): Array<any> {
            return [
                {
                    text: 'ВАШИ ПРОЕКТЫ',
                    to: '/projects',
                },
                {
                    text: this.project.name,
                    to: `/project/${this.project.id}`,
                },
                {
                    text: 'Доктор DH',
                    to: '',
                    disabled: true,
                },
            ]
        },
        marks(): DoctorReportMarkData[] {
            return Object.keys(this.dashboardData)
                .map(key => {
                    const markInfo = _.get(DOCTOR_REPORT_MARK_INFO, key)
                    if (!markInfo) return
                    return { ...markInfo, id: key, value: _.get(this.dashboardData, key) }
                })
                .filter(el => el)
        },
    },
    methods: {
        async initData(initital?: boolean) {
            this.dashboardDataLoading = true
            if (initital) {
                const responses = await Promise.all([
                    api.project.getType({ projectId: this.project.id }).then(res => res.data?.type!),
                    api.project.getNetworkProjects({ projectId: this.project.id }).then(res => res.data!),
                ])
                this.projectType = responses[0]
                this.filialList =
                    this.projectType === 'network' ? responses[1].filter(el => !el.isMain) : responses[1]
                this.selectedProjectId =
                    this.projectType === 'network' ? this.filialList[0].id : this.project.id
            }

            const { data, error } = await api.gcb2.getDoctorReportData({
                projectId: this.selectedProjectId!,
            })
            if (error) {
                this.$store.dispatch('callNotify', 'Ошибка')
            }
            this.dashboardData = data
            this.dashboardDataLoading = false
        },
    },
    async mounted() {
        this.initData(true)
    },
})
