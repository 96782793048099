
import { defineComponent, ref, PropType, computed } from 'vue'
import { DoctorReportMarkData } from '../types'
import { DOCTOR_REPORT_MARK_INFO } from '../consts'
import * as _ from 'lodash'

export default defineComponent({
    props: {
        markData: { type: Object as PropType<DoctorReportMarkData>, required: true },
    },
    setup(props, { emit }) {
        const markType = computed(() => {
            const markInfo = _.get(DOCTOR_REPORT_MARK_INFO, props.markData.id)
            if (parseFloat(String(props.markData.value)) >= parseFloat(markInfo.high.value)) return 'high'
            if (parseFloat(String(props.markData.value)) >= parseFloat(markInfo.medium.value)) return 'medium'
            return 'low'
        })

        const icon = computed(() => {
            if (markType.value === 'high') return '$greenGradientCheckmark'
            if (markType.value === 'medium') return '$yellowGradientWarning'
            if (markType.value === 'low') return '$redGradientLightning'
        })

        const prettifyValue = (val: string) => String(val).replaceAll('.', ',')
        return { markType, icon, prettifyValue }
    },
})
